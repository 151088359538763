.react-calendar {
  width: 590px;
  margin-bottom: 10px;
  border-color: #eaeaea;
}

.react-calendar__tile {
  height: 60px;
}

@media only screen and (max-width: 600px) {
  .react-calendar {
    width: 300px;
  }
  .react-calendar__tile {
    height: 30px;
  }
}

.react-calendar__tile--active {
  background: #1da57a;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1da57a;
}
